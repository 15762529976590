<template>
  <div>
    <Header :showBackButton="false" class="page-anim1" />

    <section>
      <div class="container">
        <div class="filters">
          <div class="search-item">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Pesquisar artigos"
            />
            <Magnify class="pointer" @click="search" />
          </div>
          <select @change="search" v-model="selectedCategory">
            <option value="">Categoria: Todas</option>
            <option v-for="cat in categories" :key="cat.id" :value="cat.id">
              {{ cat.name }}
            </option>
          </select>
        </div>

        <div
          class="title mt-3 tablet-hidden mobile-hidden color-primary animated fadeInLeft"
        >
          Artigos mais recentes
        </div>

        <transition enter-active-class="animated zoomIn">
          <div
            v-show="!loading && posts.length > 0"
            class="posts"
            v-infinite-scroll="getposts"
            infinite-scroll-disabled="allPostsLoaded"
            infinite-scroll-distance="10"
          >
            <PostCard
              v-for="post in posts"
              :key="post.id"
              :id="post.id"
              :slug="post.slug"
              :title="post.title"
              :summary="post.summary"
              :image="post.image"
              :timestamp="post.timestamp"
            />
          </div>
        </transition>

        <div
          v-show="loading"
          class="flex-center desktop12 tablet12 mobile12 my-2"
        >
          <div class="loading2"></div>
        </div>
      </div>
    </section>

    <Navbar class="desktop-hidden" />
  </div>
</template>
<script>
import http from "@/http";
import authentication from "../util/authentication";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import PostCard from "../components/PostCard";
import Magnify from "vue-material-design-icons/Magnify.vue";

export default {
  name: "Home",
  components: {
    Header,
    Navbar,
    PostCard,
    Magnify,
  },
  data() {
    return {
      loading: false,
      selectedCategory: "",
      take: 18,
      page: 1,
      posts: [],
      categories: [],
      searchQuery: "",
      allPostsLoaded: false,
    };
  },
  created() {
    this.getCategories();
    this.getposts();
  },
  methods: {
    getposts() {
      if (this.loading || this.allPostsLoaded) return;

      this.loading = true;
      http
        .get(
          `post/list?take=${this.take}&page=${this.page}&categoryId=${this.selectedCategory}&search=${this.searchQuery}&orderBy=timestamp`,
          authentication.getHeaders()
        )
        .then((response) => {
          if (this.page < response.data.pages) {
            this.page++;
          } else {
            this.allPostsLoaded = true;
          }
          this.posts = [...this.posts, ...response.data.list];
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    search() {
      this.posts = [];
      this.page = 1;
      this.allPostsLoaded = false;
      this.getposts();
    },
    getCategories() {
      http
        .get("postCategory/list?take=-1")
        .then((response) => {
          this.categories = response.data.list;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.posts {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
  gap: 20px;
  margin-top: 30px;
}

.filters {
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 30px;
  .search-item {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid var(--primary);
    input {
      position: relative;
      width: calc(100% - 30px);
      font-size: 1.05em;
      &::placeholder {
        font-size: 1.05em;
      }
    }
  }
  select {
    position: relative;
    width: 40%;
    height: 28px;
    color: var(--dark5);
    font-size: 1.05em;
    border-bottom: 1px solid var(--primary);
  }
}

@media screen and (min-width: 1160px) {
}
</style>
